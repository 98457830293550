body {
  font-family: "Montserrat" !important;
}

a {
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
}

/* ==========================
-- LOGO
========================== */
.size-1 {
  max-width: 150px !important;
}

.logo img {
  width: 180px;
}

.img-tanks {
  max-width: 500px;
}

/* ==========================
-- LIST
========================== */

.item-list .icon-item-list img {
  width: 43px;
}

/* ==========================
-- TEXTS
========================== */
.text-1 {
  font-size: 12px;
}

.text-2 {
  font-size: 14px;
}

.text-3 {
  font-size: 16px;
}

.text-4 {
  font-size: 18px;
}

.title {
  font-weight: bold;
  font-size: 26px;
}

.title-2 {
  font-weight: bold;
  font-size: 20px;
}

.title-3 {
  font-weight: bold;
  font-size: 16px;
}

.title-link {
  font-weight: bold;
  font-size: 14px;
}

.title-4 {
  font-weight: bold;
  font-size: 32px;
}

.title-5 {
  font-weight: bold;
  font-size: 40px;
}

.title-6 {
  font-weight: bold;
  font-size: 50px;
}

/* ==========================
-- ELEMENTS
========================== */
.text-shadow-1 {
  text-shadow: 1px 3px 9px rgba(83, 83, 83, 0.6);
}

.shadow-one {
  -webkit-box-shadow: -1px 3px 31px -4px rgba(0, 0, 0, 0.48);
  box-shadow: -1px 3px 31px -4px rgba(0, 0, 0, 0.48);
  background-color: #fff;
}

.inp {
  box-shadow: 6px -2px 24px -10px rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: 6px -2px 24px -10px rgba(0, 0, 0, 0.74);
  -moz-box-shadow: 6px -2px 24px -10px rgba(0, 0, 0, 0.74);
  border: 1px solid #ddd;
}

.title-shadow-one {
  background: linear-gradient(360deg, #B5963E 25%, #F6D867 100%);
  padding: 0;
  width: 100%;
  display: inline-block;
  padding: 25px;
  color: #fff;
}

.title-shadow-success {
  background: #51af34;
  padding: 0;
  width: 100%;
  display: inline-block;
  padding: 25px;
  color: #fff;
}

.title-shadow-primary {
  background: linear-gradient(360deg, #000000 25%, #454545 100%);
  padding: 0;
  width: 100%;
  display: inline-block;
  padding: 25px;
  color: #fff;
}

.title-shadow-one-top {
  position: absolute;
  top: 0;
  left: 0;
}


/* ==========================
-- COLORS AND BACKGROUNDS
========================== */
.color-white {
  color: #FFFFFF !important;
}

.color-1 {
  color: #FE6A88 !important;
}

.bg-color-1 {
  background-color: #FE6A88 !important;
}

.color-2 {
  color: #4C4849 !important;
}

.bg-color-2 {
  background-color: #4C4849 !important;
}

.color-3 {
  color: #B42844 !important;
}

.bg-color-3 {
  background-color: #B42844 !important;
}

.color-4 {
  color: #737071 !important;
}

.bg-color-4 {
  background-color: #737071 !important;
}

.bg-default {
  background: linear-gradient(360deg, #d9b140 25%, #F6D867 100%);
}

/* 63ea8070c7eb3f003d4f22fd */
/* .link-one {
  -webkit-transition: background 0.2s 0s linear;
  -moz-transition: background 0.2s 0s linear;
  -o-transition: background 0.2s 0s linear;
  transition: background 0.2s 0s linear;
} */
.link-disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.line-link-one {
  height: 8px;
  width: 100%;
  background-color: #333;
  opacity: 0.4;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.line-link-one-fill {
  height: 100% !important;
  background-color: #000;
  opacity: 0.5;
  transition: all 0.5s;
}

.link-one-clicked {
  border: 8px solid #333 !important;
}

.link-one {
  /* border: 1px solid #fff; */
  position: relative;
}

.link-one h5 {
  position: relative;
  z-index: 100;
}

.link-one h5:hover {
  color: #fff;
}

.link-one:after {
  position: absolute;
  /* border-radius: 50%; */
  content: "";
  z-index: 10;
  /* depends on your need change to 1 if want to overlap the div on hover*/
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  transition: all 0.3s;
}

.link-hover-3:hover:after {
  box-shadow: 0 0 0 5px #912339;
}

.link-hover-4:hover:after {
  box-shadow: 0 0 0 5px #5c5759;
}

.link-hover-success:hover:after {
  box-shadow: 0 0 0 5px green;
}

.wrapper-body {
  background: linear-gradient(360deg, #000000 25%, #454545 100%);
}

.wrapper-box {
  position: relative;
  width: 100%;
}

.shadow-one-opacited {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
}

.box-shadow {
  position: absolute;
  height: 100%;
  width: 102%;
  background: rgba(255, 255, 255, 0.3);
  top: 10px;
  left: 13px;
  filter: blur(5px);
}
// thanks page
@media (max-width: 768px) {
  .checkout-div {
    margin-top: 3rem;
  }

  .whatTodoNow {
    margin-top: 3rem !important; 
  }
}